import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeImages from "../../../composants/layout/en/LayoutGalerieListeImages"

import { obtenirImages, obtenirPage } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGalerieVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "galeriePhenomenesEN" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: { nin: ["haloLunaire"] }
        en: { code_galerie: { eq: "phenomenesEN" } }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["haloLunaire"] } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function IndexGalerieVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGalerieVoieLactee")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeImages page={page} images={images} />
}
